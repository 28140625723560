import startOfDay from "date-fns/startOfDay";
import { storeToRefs } from "pinia";
import type { QInfiniteScroll } from "quasar";
import type { Ref } from "vue";
import { computed, ref, unref, watch } from "vue";

import { ErrorDialog } from "shared/boot/alert";
import { getLocaleText } from "shared/boot/i18n";
import {
  DAY_IN_SECONDS,
  defaultMentionsFields,
  socialAutoRefreshEnabled,
  streamTypes,
} from "shared/constants";
import { dateToTimestamp, subtractTime } from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";
import type { MediumName } from "shared/helpers/media";
import {
  SortOptionField,
  SortOptionMissing,
  SortOptionOrder,
} from "shared/helpers/mentions";
import type {
  StreamFiltersSortOption,
  StreamRequestFilters,
  StreamRequestFiltersSortOption,
} from "shared/helpers/StreamFilters";
import fetchMentions from "shared/services/fetching/fetchMentions";
import { useGlobalStore } from "shared/stores/global";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useSyndicationStore } from "shared/stores/syndication";
import { useUserStore } from "shared/stores/user";
import type { Nullable, Stream } from "shared/types";
import type { Mention } from "shared/types/mentions";

export const AUTO_REFRESH_MENTIONS_LIMIT = 3;

export interface RequestOptions {
  allFields?: boolean;
  allowFutureContent?: boolean;
  defaultRange?: DateRange;
  fetchingOptions?: MentionsOptions;
  fetchingService?: typeof fetchMentions;
  fields?: string;
  includeBookmarkSyndication?: boolean;
  infiniteScroll?: Ref<InstanceType<typeof QInfiniteScroll> | undefined>;
  missing?: SortOptionMissing;
  orderBy?: SortOptionOrder;
  page?: number;
  pageSize?: number;
  paginate?: boolean;
  sortBy?: SortOptionField;
  stream: Stream;
  transformMentions?: (mentions: Mention[]) => Mention[];
  useMilliseconds?: boolean;
}

export interface MentionsOptions {
  allFields?: boolean;
  allowFutureContent?: false;
  clearMentionsCount?: boolean;
  endpoint?: string;
  excludedMedia?: MediumName[];
  fields?: string;
  forceReload?: boolean;
  includeBookmarkSyndication?: boolean;
  keepAlreadyLoadedMentions?: boolean;
  limit?: number;
  loadLatestMentions?: boolean;
  missing?: SortOptionMissing;
  orderBy?: SortOptionOrder;
  page?: number;
  pageSize?: number;
  paginate?: boolean;
  range?: DateRange;
  search?: StreamRequestFilters;
  sort_options?: StreamRequestFiltersSortOption[];
  sortBy?: SortOptionField;
  sortingOptions?: StreamFiltersSortOption[];
  source_group_keys?: string[];
  source_keys?: string[];
  stream_ids?: number[];
  transformMentions?: (mentions: Mention[]) => Mention[];
  useMilliseconds?: boolean;
}

interface RequestParams extends MentionsOptions {
  syndicationKeys: string[];
  timeout: number;
  range: DateRange;
}

export default function useRequestMentions(options: RequestOptions) {
  const allFields = computed(() => unref(options.allFields || false));

  const allowFutureContent = computed(() =>
    unref(options.allowFutureContent || false)
  );

  const defaultRange = computed(() =>
    unref(options.defaultRange || DateRange.lastThirtyDays())
  );

  const fetchingOptions = computed(() => unref(options.fetchingOptions || {}));

  const fetchingService = computed(() =>
    unref(options.fetchingService || fetchMentions)
  );

  const fields = computed(() => unref(options.fields || defaultMentionsFields));

  const includeBookmarkSyndication = computed(() =>
    unref(options.includeBookmarkSyndication || false)
  );

  const { infiniteScroll = ref() } = options;

  const missing = computed(() =>
    unref(options.missing || SortOptionMissing.LAST)
  );

  const orderBy = computed(() =>
    unref(options.orderBy || SortOptionOrder.DESC)
  );

  const page = ref(options.page || 0);
  const pageSize = ref(options.pageSize || 10);

  const paginate = computed(() => unref(options.paginate || false));

  const sortBy = computed(() =>
    unref(options.sortBy || SortOptionField.TIMESTAMP)
  );

  const stream = computed<Stream>(() => unref(options.stream));

  const transformMentions = computed(() => {
    if (options.transformMentions) {
      unref(options.transformMentions);
    }

    return (mentions: Mention[]) => mentions;
  });

  const useMilliseconds = computed(() =>
    unref(options.useMilliseconds || true)
  );

  const { getContentDateCap, getLowestSearcheableDate } =
    storeToRefs(useGlobalStore());

  const defaultRequestOptions = computed<MentionsOptions>(() => ({
    paginate: paginate.value,
    allFields: allFields.value,
    fields: fields.value,
    useMilliseconds: useMilliseconds.value,
    sortingOptions: [
      {
        sortBy: sortBy.value,
        orderBy: orderBy.value,
        missing: missing.value,
      },
    ],
    transformMentions: transformMentions.value,
    ...fetchingOptions.value,
  }));

  const requestFilters = ref<StreamRequestFilters>({});
  const loading = ref(false);
  const loadingIds = ref(false);
  const loadingError = ref(false);
  const mentions = ref<Mention[]>([]);
  const mentionIds = ref<number[]>([]);
  const totalPages = ref(1);
  const totalMentions = ref(0);
  const queuedLoad = ref<Nullable<MentionsOptions>>(null);
  const receivedLessMentionsThanRequested = ref(false);
  const lastRefreshedRange = ref<Nullable<DateRange>>(null);

  const syndicationStore = useSyndicationStore();

  const { currentUser, organisation } = storeToRefs(useUserStore());
  const { getSyndicationKeysForStream } = storeToRefs(syndicationStore);
  const { clearMentionsCount } = useMentionCountsStore();
  const { resetSyndicationKeys, updateSyndicationKeys } = syndicationStore;

  const rangeLimit = computed(() => {
    const maxDateRangeValue =
      organisation.value.max_date_range || getContentDateCap.value();

    const timestamp =
      maxDateRangeValue.toString().length >= 10
        ? maxDateRangeValue
        : dateToTimestamp(
            startOfDay(subtractTime(new Date(), maxDateRangeValue, "month"))
          );

    const lowestAllowedDate = dateToTimestamp(
      getLowestSearcheableDate.value(new Date())
    );

    return timestamp > lowestAllowedDate ? timestamp : lowestAllowedDate;
  });

  function resetMentionsState(mentionsOptions: MentionsOptions): void {
    mentions.value = [];
    resetSyndicationKeys({ stream: stream.value });

    // We need a timeout because otherwise "stream" watchers in wordCloud and sentiment widget
    // Are called only one time when changing filters (with onlyLastSeenUpdated)
    // Instead of two, one without onlyLastSeenUpdated and one with
    // So they are not updated
    if (stream.value.id && mentionsOptions.clearMentionsCount) {
      setTimeout(
        () =>
          clearMentionsCount({
            ...stream.value,
            resetByGroup: false,
          }),
        100
      );
    }

    if (mentionsOptions.paginate) {
      page.value = mentionsOptions.page || 1;
    }
  }

  function getSearchRange(mentionsOptions: MentionsOptions): DateRange {
    const autoRefresh =
      currentUser.value.auto_refresh_streams ||
      (stream.value.type === streamTypes.socialStream &&
        socialAutoRefreshEnabled);

    let lastRefreshedAt = dateToTimestamp(
      (!autoRefresh &&
        !mentionsOptions.forceReload &&
        stream.value.refreshed_at) ||
        new Date()
    );

    // always load the latest bookmarks
    if (stream.value.type === streamTypes.bookmarkStream) {
      lastRefreshedAt = dateToTimestamp(new Date());
    }

    if (mentionsOptions.loadLatestMentions) {
      lastRefreshedAt = dateToTimestamp(new Date());
    }

    const rangeInDays = defaultRange.value.range?.daysBack;

    const rangeFromLastRefresh =
      defaultRange.value.range && rangeInDays
        ? {
            after: dateToTimestamp(
              subtractTime(
                startOfDay(lastRefreshedAt * 1000),
                rangeInDays,
                "day"
              )
            ),
            before: lastRefreshedAt,
          }
        : defaultRange.value;

    const range = {
      ...(requestFilters.value?.range ||
        mentionsOptions.range ||
        rangeFromLastRefresh),
    };

    if (range.after < rangeLimit.value) {
      range.after = rangeLimit.value;
      if (rangeInDays)
        range.before = rangeLimit.value + DAY_IN_SECONDS * rangeInDays;
    }

    const currentDate = dateToTimestamp(new Date());
    if (!allowFutureContent.value && range.before > currentDate)
      range.before = currentDate;

    return new DateRange({ after: range.after, before: range.before });
  }

  function setFilters(
    filters: StreamRequestFilters = {}
  ): StreamRequestFilters {
    requestFilters.value = filters;

    return requestFilters.value;
  }

  function getRequestParams(mentionsOptions: MentionsOptions): RequestParams {
    const params: RequestParams = {
      ...mentionsOptions,
      range: getSearchRange(mentionsOptions),
      timeout: 90000,
      syndicationKeys: getSyndicationKeysForStream.value(stream.value.id),
    };

    if (params.paginate) {
      const paginateOptions = {
        page: params.page || page.value,
        limit: params.limit || pageSize.value,
      };

      Object.assign(params, { ...paginateOptions });
    }

    if (Object.keys(requestFilters.value).length) {
      params.search = requestFilters.value;
    }

    params.sort_options = params.sortingOptions?.map((option) => ({
      sort_by: option.sortBy,
      sort_order: option.orderBy,
      missing: option.missing,
    }));

    const excludedKeys: string[] = [
      "fetchingService",
      "defaultRange",
      "transformMentions",
      "sortingOptions",
      "paginate",
    ];

    excludedKeys.forEach((key) => delete params[key as keyof RequestParams]);

    if (stream.value.type === streamTypes.bookmarkStream) {
      params.includeBookmarkSyndication =
        includeBookmarkSyndication.value as boolean;
    }

    return params;
  }

  function processAndSetMentions(
    rawMentions: Mention[],
    mentionsOptions: MentionsOptions
  ): void {
    const processedMentions: Mention[] =
      mentionsOptions.transformMentions!(rawMentions) || [];

    if (
      mentionsOptions.keepAlreadyLoadedMentions &&
      !mentionsOptions.loadLatestMentions
    ) {
      mentions.value.push(...processedMentions);
    } else if (
      mentionsOptions.keepAlreadyLoadedMentions &&
      mentionsOptions.loadLatestMentions
    ) {
      const mentionIdList = mentions.value.map((mention) => mention.id);

      const newMentions = processedMentions.filter(
        (mention) =>
          !mentionIdList.includes(mention.id) &&
          mention.timestamp_milliseconds >
            mentions.value[0].timestamp_milliseconds
      );

      if (newMentions.length) {
        mentions.value.unshift(...newMentions);
        // remove the same number of mentions we're adding so that we avoid
        // Out Of Memory Error in the browser when auto refreshing for a
        // long period of time.
        mentions.value.splice(newMentions.length * -1, newMentions.length);

        setTimeout(() => clearMentionsCount(stream.value), 100);
      }
    } else {
      mentions.value = processedMentions;
    }

    if (!mentionsOptions.paginate) {
      updateSyndicationKeys({
        stream: stream.value,
        mentions: processedMentions,
        options: mentionsOptions,
      });
    }
  }

  async function loadMentionIds(
    loadOptions: MentionsOptions = {}
  ): Promise<void> {
    if (!stream.value) return;
    if (loadingIds.value) return;

    loadingIds.value = true;

    const mentionsOptions = { ...defaultRequestOptions.value, ...loadOptions };
    const requestParams = getRequestParams(mentionsOptions);

    try {
      const { data } = await fetchingService.value.getIds({
        stream: stream.value,
        mentions: mentions.value,
        options: requestParams,
      });

      mentionIds.value = [...data];
    } catch (error) {
      ErrorDialog(
        getLocaleText("request_mentions.error_loading_mention_ids"),
        error
      );
    } finally {
      loadingIds.value = false;
    }
  }

  async function loadMentions(loadOptions: MentionsOptions = {}) {
    if (!stream.value.id && !stream.value.skipReload) return;

    if (loading.value) {
      queuedLoad.value = loadOptions;

      return;
    }

    loading.value = true;
    loadingError.value = false;

    const mentionsOptions = { ...defaultRequestOptions.value, ...loadOptions };

    if (
      !mentionsOptions.keepAlreadyLoadedMentions ||
      mentionsOptions.forceReload
    ) {
      resetMentionsState(mentionsOptions);
    }

    const requestParams = getRequestParams(mentionsOptions);

    lastRefreshedRange.value = new DateRange(requestParams.range as DateRange);

    try {
      const { data, headers } = await fetchingService.value.get({
        stream: stream.value,
        mentions: mentions.value,
        options: requestParams,
      });

      processAndSetMentions(data, mentionsOptions);

      receivedLessMentionsThanRequested.value =
        data.length < (requestParams.limit || 10);

      totalMentions.value =
        Number(headers["x-total-count"]) || mentions.value.length;

      totalPages.value = Number(headers["x-total-pages"]) || 1;

      if (infiniteScroll.value) {
        if (
          receivedLessMentionsThanRequested.value &&
          !mentionsOptions.loadLatestMentions
        ) {
          infiniteScroll.value.stop();
        } else {
          infiniteScroll.value.resume();
        }
      }
    } catch (error: any) {
      if (error && error.code === "ECONNABORTED") {
        return;
      }

      if (infiniteScroll.value) infiniteScroll.value.stop();
      loadingError.value = true;
    } finally {
      loading.value = false;

      if (queuedLoad.value) {
        loadMentions(queuedLoad.value);
        queuedLoad.value = null;
      }
    }
  }

  async function loadPage(
    pageNumber: number,
    loadOptions: MentionsOptions = {}
  ): Promise<boolean> {
    if (loading.value) return false;

    await loadMentions({
      page: pageNumber,
      keepAlreadyLoadedMentions: false,
      ...loadOptions,
    });

    return true;
  }

  async function loadPreviousPage(
    loadOptions: MentionsOptions = {}
  ): Promise<void> {
    if (totalPages.value > 1 && page.value > 1) {
      page.value -= 1;
      await loadPage(page.value, loadOptions);
    }
  }

  async function loadNextPage(
    loadOptions: MentionsOptions = {}
  ): Promise<void> {
    if (totalPages.value > 1 && page.value + 1 <= totalPages.value) {
      page.value += 1;
      await loadPage(page.value, loadOptions);
    }
  }

  async function loadLatestMentions(): Promise<boolean> {
    if (loading.value) return false;

    await loadMentions({
      keepAlreadyLoadedMentions: true,
      loadLatestMentions: true,
      paginate: true,
      limit: AUTO_REFRESH_MENTIONS_LIMIT,
    });

    return true;
  }

  async function refresh(loadOptions: MentionsOptions = {}): Promise<void> {
    await loadMentions({
      keepAlreadyLoadedMentions: false,
      ...loadOptions,
    });
  }

  watch(
    () => currentUser.value.currency_code,
    () => {
      refresh();
    }
  );

  return {
    loading,
    loadingIds,
    loadingError,
    queuedLoad,
    receivedLessMentionsThanRequested,
    rangeLimit,
    lastRefreshedRange,
    mentions,
    mentionIds,
    page,
    pageSize,
    totalPages,
    totalMentions,
    setFilters,
    loadMentions,
    loadPreviousPage,
    loadNextPage,
    loadPage,
    loadLatestMentions,
    refresh,
    loadMentionIds,
  };
}
