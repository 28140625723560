<template>
  <div
    v-if="domainAuthority"
    class="tw-flex tw-flex-nowrap"
  >
    <div :class="dense ? 'tw-mr-0.5 tw-pt-0.5' : 'tw-ml-1 tw-mr-2 tw-pt-1.5'">
      <BaseIcon
        :style="{ 'font-size': dense ? '20px' : '18px' }"
        icon="domain-authority"
        class="text-ds-denim-9"
      />
    </div>

    <div :class="dense ? 'tw-text-xxs' : 'tw-text-base tw-leading-5'">
      {{ getLocaleText("domain_authority_widget.label") }}

      <div
        class="tw-text-left tw-font-bold"
        :class="{ 'tw-text-xxs': dense }"
      >
        {{ domainAuthority }}
      </div>
    </div>

    <StreemTooltip>
      <div class="tw-p-2">
        {{ getLocaleText("domain_authority_widget.tooltip") }}
      </div>
    </StreemTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { BaseIcon, StreemTooltip } from "shared/components/base";
import { type Mention } from "shared/types/mentions";
import { isOnlineArticle } from "shared/types/mentions/guards";

interface DomainAuthorityWidgetProps {
  mention: Mention;
  dense?: boolean;
}

const props = defineProps<DomainAuthorityWidgetProps>();

const domainAuthority = computed(
  () => isOnlineArticle(props.mention) && props.mention.source?.domain_authority
);
</script>
