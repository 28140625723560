<template>
  <div
    class="column full-height no-wrap full-width stream"
    style="min-height: 0"
  >
    <template v-if="$isMobile && onNavBar">
      <RangeSelector
        v-if="!hideRangeSelector && rangeSelectorActive"
        v-model:range="range"
        @update:range="onRangeUpdated"
      />
    </template>

    <div
      v-show="!embeddedPreview"
      class="no-shrink"
    >
      <div
        v-if="!onNavBar && !hideButtonBar"
        class="card-column-header relative"
        :class="$isMobile && 'mobile'"
      >
        <div
          class="mention-stream-title tw-flex tw-flex-1 tw-items-center tw-gap-1 tw-overflow-hidden tw-pr-1 tw-text-[15px] tw-font-bold"
        >
          <slot name="title">
            <div
              v-if="stream.shared"
              class="shared-icon tw-flex-shrink-0"
            />
            <EllipseContent>
              {{ streamTitle }}
            </EllipseContent>
          </slot>

          <slot name="resize" />
        </div>

        <slot name="header" />

        <MentionStreamHeaderToolbar
          v-model:range="range"
          :allow-all-time="allowAllTime"
          :filtering="filtering"
          :filters-selector-active="filtersSelectorActive"
          :group-slug="groupSlug"
          :hide-edit-button="hideEditButton"
          :hide-filters-selector="hideFiltersSelector"
          :hide-generate-report-button="hideGenerateReportButton"
          :hide-range-selector="hideRangeSelector"
          :stream="stream"
          @filters="activateSelector('filters')"
        >
          <template #prependButtonBar>
            <slot name="prependButtonBar" />
          </template>
          <template #buttonBar>
            <slot name="buttonBar" />
          </template>
        </MentionStreamHeaderToolbar>
      </div>

      <div class="card-header overflow-visible">
        <div
          v-if="loadingError"
          class="banner text-white smaller no-shrink pointer row justify-center items-center q-pa-smd bold"
          @click="onLoadingErrorRetry"
        >
          <QIcon
            name="ion-md-alert"
            class="q-mr-sm"
            size="1.5em"
          />{{ $t("mention_stream.error_loading_mentions") }}
        </div>

        <ExpandableFilters
          v-if="!hideFiltersSelector && stream"
          v-show="!hideFiltersSelector && stream && filtersSelectorActive"
          show-apply
          :stream="stream"
          :filters="filters"
          :stick-filters="stickFilters"
          @reset="onFilterReset"
          @apply="onFilterApply"
        />

        <RangeSelector
          v-if="!onNavBar && !hideRangeSelector && rangeSelectorActive"
          v-model:range="range"
          @update:range="onRangeUpdated"
        />

        <MentionStreamNotificationWatcher
          v-if="showNotificationWatcher"
          :stream="stream"
          @update="refreshMentions"
          @auto-update="autoRefreshMentions"
        />

        <MentionStreamExpiryWatcher
          v-if="streamExpiresSoon"
          :stream="stream"
        />

        <template
          v-if="filtering && !filtersSelectorActive && !embeddedPreview"
        >
          <slot
            name="filtering-header"
            :filtering-label="filteringLabel"
            :display-filters-selector="displayFiltersSelector"
          >
            <MentionStreamFilteringHeader
              :label="filteringLabel"
              @click="displayFiltersSelector"
            />
          </slot>
        </template>
      </div>

      <slot name="beforeMentions" />
    </div>

    <Component
      :is="$isMobile && onNavBar ? 'QPullToRefresh' : 'VFragment'"
      v-if="!paginate"
      @refresh="refreshMentions"
    >
      <QInfiniteScroll
        v-show="!embeddedPreview"
        ref="infiniteScroll"
        :scroll-target="scrollTarget"
        :class="infiniteScrollClass"
        @load="loadMore"
      >
        <SkeletonMentionStream v-if="showSkeletonMentionStream" />

        <MentionStreamList
          v-if="active && !showSkeletonMentionStream"
          :mentions="mentions"
          :stream="stream"
          :range="range"
          :gutter-class="gutterClass"
          :show-more-content-option="showNoRangeSelectedMessage"
          :options="slimViewOptions"
          @mention-clicked="mentionClicked"
          @mention-removed="removeMention"
        >
          <template #emptyMentions>
            <slot name="noMentions" />
          </template>
        </MentionStreamList>

        <div
          v-if="showNoRangeSelectedMessage && receivedLessMentionsThanRequested"
          class="q-mt-md"
        >
          <SkeletonMentionStream v-if="loading && mentions.length" />
          <div
            v-if="!loading"
            :class="$isMobile ? 'q-mx-sm q-my-md' : 'q-ml-xs'"
          >
            <div v-if="!mentions.length">
              <strong
                class="q-mt-xl q-mb-md column justify-center items-center"
              >
                {{ $t("mention_stream.no_media_items_found") }}
              </strong>
            </div>
            <div class="soft q-mb-xl text-center">
              <span v-if="!mentions.length">{{
                $t("mention_stream_notification_watcher.no_media_items_since", {
                  date: formatIntlDate(range.after, { dateStyle: "long" }),
                })
              }}</span>
              <span v-else>{{
                $t("mention_stream_notification_watcher.media_items_since", {
                  date: formatIntlDate(range.after, { dateStyle: "long" }),
                })
              }}</span>
              <div v-if="!reachedOneYear">
                <span
                  class="bold dashed-underline pointer"
                  @click="extendRange()"
                >
                  {{
                    $t(
                      "mention_stream_notification_watcher.search_earlier_content_button"
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <template
          v-if="
            !showNoRangeSelectedMessage || !receivedLessMentionsThanRequested
          "
          #loading
        >
          <SkeletonMentionStream class="q-mt-xs" />
        </template>
      </QInfiniteScroll>
    </Component>

    <div
      v-else
      v-show="!embeddedPreview"
      class="pagination-container"
    >
      <SkeletonMentionStream v-if="loading && !mentions.length" />

      <MentionStreamList
        v-if="active && (!loading || mentions.length)"
        :mentions="mentions"
        :stream="stream"
        :no-highlighting="noHighlighting"
        @mention-clicked="mentionClicked"
        @mention-removed="removeMention"
      >
        <template #emptyMentions>
          <slot name="noMentions" />
        </template>
      </MentionStreamList>

      <Pagination
        v-if="!loading && totalPages > 1"
        :page="page"
        :total-pages="totalPages"
        @next="loadNextPage"
        @previous="loadPreviousPage"
      />
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash-es";
import { useMobileStore } from "mobile/src/stores/mobile";
import { storeToRefs } from "pinia";
import { QPullToRefresh } from "quasar";
import { reactive, ref, toRefs } from "vue";

import {
  BaseButton,
  BaseButtonGroup,
  EllipseContent,
  InputDatePicker,
} from "shared/components/base";
import MentionStreamExpiryWatcher from "shared/components/MentionStreamExpiryWatcher.vue";
import MentionStreamFilteringHeader from "shared/components/MentionStreamFilteringHeader.vue";
import MentionStreamList from "shared/components/MentionStreamList.vue";
import MentionStreamNotificationWatcher from "shared/components/MentionStreamNotificationWatcher.vue";
import ExpandableFilters from "shared/components/selectors/ExpandableFilters.vue";
import Pagination from "shared/components/selectors/Pagination.vue";
import RangeSelector from "shared/components/selectors/RangeSelector.vue";
import SkeletonMentionStream from "shared/components/SkeletonMentionStream.vue";
import MentionStreamHeaderToolbar from "shared/components/streams/MentionStreamHeaderToolbar.vue";
import VFragment from "shared/components/VFragment.vue";
import useRequestMentions from "shared/composables/useRequestMentions";
import useStreamFiltering from "shared/composables/useStreamFiltering";
import {
  DAY_IN_MILLISECONDS,
  STREAM_EXTENDED_EXPIRY_DAYS,
} from "shared/constants";
import { formatIntlDate } from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";
import { expiryHasExtended, isSameStream } from "shared/helpers/stream";
import StreamFilters from "shared/helpers/StreamFilters";
import { useOrganisationFeaturesStore } from "shared/stores/organisationFeatures";
import { useStreamsStore } from "shared/stores/streams";
import { useUserStore } from "shared/stores/user";

export default {
  name: "MentionStream",
  components: {
    QPullToRefresh,
    VFragment,
    MentionStreamExpiryWatcher,
    MentionStreamFilteringHeader,
    MentionStreamList,
    MentionStreamNotificationWatcher,
    Pagination,
    InputDatePicker,
    RangeSelector,
    SkeletonMentionStream,
    ExpandableFilters,
    BaseButton,
    BaseButtonGroup,
    EllipseContent,
    MentionStreamHeaderToolbar,
  },
  provide() {
    const that = this;

    return {
      get filters() {
        return that.providedFilters;
      },
      get groupSlug() {
        return that.groupSlug;
      },
    };
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
    groupSlug: {
      type: String,
      default: "",
    },
    fetchingService: {
      type: Object,
      required: true,
    },
    defaultRange: {
      type: DateRange,
      default: () => DateRange.lastThirtyDays(),
    },
    streamFilters: {
      type: [StreamFilters, null],
      default: null,
    },
    fetchingOptions: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "",
    },
    sortBy: {
      type: String,
      default: "timestamp",
    },
    scrollClass: {
      type: [Array, Object, String],
      default: "",
    },
    scrollTarget: {
      type: String,
      default: "",
    },
    hideButtonBar: {
      type: Boolean,
    },
    hideNotificationWatcher: {
      type: Boolean,
    },
    hideFiltersSelector: {
      type: Boolean,
    },
    hideEditButton: {
      type: Boolean,
    },
    hideGenerateReportButton: {
      type: Boolean,
    },
    hideStreamVisualisationButton: {
      type: Boolean,
    },
    allowAllTime: {
      type: Boolean,
    },
    noHighlighting: {
      type: Boolean,
    },
    showMoreContentOption: {
      type: Boolean,
    },
    hideRangeSelector: {
      type: Boolean,
    },
    paginate: {
      type: Boolean,
    },
    gutterClass: {
      type: String,
      default: "q-gutter-sm",
    },
    // Mobile specific props
    onNavBar: {
      type: Boolean,
    },
    editMention: {
      type: Boolean,
    },
    stickFilters: {
      type: Boolean,
    },
    slimView: {
      type: Boolean,
    },
    noSquareTopBorder: {
      type: Boolean,
    },
    initialScrollPosition: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    "update:range",
    "mention-clicked",
    "refreshed",
    "navbar-button-click",
    "update:scrollPosition",
  ],
  setup(props) {
    const { refreshUsedCredits } = useOrganisationFeaturesStore();

    const infiniteScroll = ref(null);

    const {
      loading,
      loadingError,
      receivedLessMentionsThanRequested,
      lastRefreshedRange,
      rangeLimit,
      page,
      totalPages,
      mentions,
      setFilters,
      loadMentions,
      loadPreviousPage,
      loadNextPage,
      refresh,
    } = useRequestMentions({
      ...toRefs(props),
      infiniteScroll,
      paginate: true,
    });

    const streamFilteringOptions = reactive({
      ...toRefs(props),
      filters: props.streamFilters,
    });

    const {
      filters,
      filtering,
      filteringLabel,
      selectorActive,
      filtersSelectorActive,
      displayFiltersSelector,
    } = useStreamFiltering(streamFilteringOptions);

    const streamsStore = useStreamsStore();
    const { setStreamFilters } = streamsStore;

    const userStore = useUserStore();
    const { organisation } = storeToRefs(userStore);

    const mobileStore = useMobileStore();
    const { setSelectedMention } = mobileStore;

    return {
      infiniteScroll,

      loading,
      loadingError,
      receivedLessMentionsThanRequested,
      lastRefreshedRange,
      rangeLimit,
      page,
      totalPages,
      mentions,
      setFilters,
      loadMentions,
      loadPreviousPage,
      loadNextPage,
      refresh,
      refreshUsedCredits,

      filters,
      filtering,
      filteringLabel,
      selectorActive,
      filtersSelectorActive,
      displayFiltersSelector,

      setStreamFilters,

      organisation,
      setSelectedMention,
    };
  },
  data() {
    return {
      rangeSelectorActive: false,
      active: false,
      embeddedPreview: false,
      scrollPosition: this.initialScrollPosition,
      spanRange: 0,
      reachedOneYear: false,
      range: this.defaultRange || DateRange.today(),
      searchingRange: false,
      navBarHandler: null,
    };
  },
  computed: {
    showSkeletonMentionStream() {
      return this.loading && !this.mentions.length;
    },
    showNoRangeSelectedMessage() {
      return !this.searchingRange && this.showMoreContentOption;
    },
    infiniteScrollClass() {
      return [this.scrollClass, this.$isDesktop ? "scroll q-pb-xs" : ""];
    },
    streamTitle() {
      if (this.title) return this.title;

      return this.stream.label;
    },
    showNotificationWatcher() {
      if (this.hideNotificationWatcher) return false;

      return this.stream && !this.filtering;
    },
    streamExpiresSoon() {
      const dateDiff = new Date(this.stream.expires_at) - new Date();

      return (
        !this.stream.expiration_dismissed &&
        this.stream.expires_at &&
        dateDiff > 0 &&
        dateDiff <= DAY_IN_MILLISECONDS
      );
    },
    providedFilters() {
      return this.filters;
    },
    slimViewOptions() {
      if (this.slimView) {
        return {
          hideAfterBodySlot: true,
          disableAuthorClick: true,
          noSquareBorders: true,
          sentimentRatingDisabled: true,
        };
      }

      return {
        noSquareTopBorder: this.noSquareTopBorder,
      };
    },
  },
  watch: {
    stream: {
      deep: true,
      handler(newStream, oldStream) {
        if (newStream.skipReload) return;

        if (isSameStream(newStream, oldStream)) {
          if (
            this.active &&
            expiryHasExtended(newStream, oldStream, STREAM_EXTENDED_EXPIRY_DAYS)
          ) {
            this.$dialog.alert({
              title: this.$t("mention_stream.expiry_extended_title"),
              message: this.$t("mention_stream.expiry_extended_message", {
                streamLabel: this.stream.label,
              }),
            });
          }

          if (this.stream.onlyLastSeenUpdated && !this.stream.resetByGroup) {
            return;
          }
        }

        this.refresh();
      },
    },
    range: {
      immediate: false,
      handler() {
        this.$emit("update:range", this.range);
      },
    },
    streamFilters(newFilters, oldFilters) {
      if (isEqual(newFilters, oldFilters)) return;

      this.filters = this.streamFilters;
      this.onFilter(this.filters);
    },
  },
  async mounted() {
    this.spanRange = this.range.span;

    this.toggleScrollListener(true);

    if (this.onNavBar) {
      if (!this.hideFiltersSelector) {
        this.$navBar.buttonClicked("filter", () => {
          if (!this.active) return false;
          this.activateSelector("filters");

          return true;
        });
      }

      if (this.editMention) {
        this.navBarHandler = this.$navBar.buttonClicked(
          "edit-mention-stream",
          () => {
            this.openEditStream();
          }
        );
      }
    }

    this.active = true;
    if (!this.streamFilters) this.filters.collapseSyndicated = true;

    if (this.streamFilters?.dateRangeEnabled) {
      this.filters.dateRangeEnabled = true;
      this.filters.range = this.range;
    }

    if (this.filters.dateRangeEnabled) {
      this.searchingRange = true;
    }

    this.page = 1;
    this.setFilters(this.filters.requestFilters());

    await this.loadMentions({
      page: this.page,
    });

    if (this.showMoreContentOption)
      this.range = new DateRange(this.lastRefreshedRange);
  },
  activated() {
    this.active = true;
    this.embeddedPreview = false;

    // Reset filtersSelector because the nav button has reseted
    this.selectorActive = null;

    this.toggleScrollListener(true);

    this.$nextTick(() => {
      this.$refs.infiniteScroll.$el.scrollTop = this.scrollPosition;
    });
  },
  deactivated() {
    this.toggleScrollListener(false);

    this.active = false;
  },
  unmounted() {
    this.toggleScrollListener(false);

    if (this.navBarHandler) this.navBarHandler.remove();
  },
  methods: {
    toggleScrollListener(enable) {
      if (!this.$refs.infiniteScroll) return;

      const method = enable ? "addEventListener" : "removeEventListener";

      this.$refs.infiniteScroll.$el[method](
        "scroll",
        this.updateScrollPosition
      );
    },
    formatIntlDate,
    async loadMore(_index, done) {
      if (!this.mentions.length) {
        this.$refs.infiniteScroll.stop();

        return done();
      }

      if (this.loading) {
        return done();
      }

      this.setFilters({
        ...this.filters.requestFilters(),
        range: this.lastRefreshedRange,
      });

      await this.loadNextPage({
        keepAlreadyLoadedMentions: Boolean(this.mentions.length),
      });

      return done();
    },
    onRangeUpdated(range) {
      this.refresh({ range });
    },
    updateScrollPosition($event) {
      this.scrollPosition = $event.target.scrollTop;
      this.$emit("update:scrollPosition", this.scrollPosition);
    },
    mentionClicked(mention) {
      this.$emit("mention-clicked", mention);
    },
    extendRange() {
      let { after, before } = this.lastRefreshedRange;

      after -= this.spanRange;
      after = Math.max(after, this.rangeLimit);
      before -= this.spanRange;

      const range = new DateRange({ before, after });

      this.range = range;
      this.$refs.infiniteScroll.resume();

      this.filters.range = new DateRange({
        before: this.filters.initialFilters.range.before,
        after,
      });

      this.page = 1;
      this.setFilters(this.filters.requestFilters());

      this.loadMentions(
        { range, keepAlreadyLoadedMentions: true, page: this.page },
        true
      );

      if (after === this.rangeLimit) this.reachedOneYear = true;
    },
    openEditStream() {
      this.$track("Opened Edit Mention Stream", {
        streamId: this.stream.id,
      });

      this.$safeRouterPush({
        name: "mention-stream-edit",
        params: {
          streamSlug: this.stream.slug,
          groupSlug: this.groupSlug,
        },
      });
    },
    autoRefreshMentions() {
      // only automatically reload if scroll at the top, otherwise show notification bar
      if (this.$refs.infiniteScroll.$el.scrollTop === 0) {
        this.refreshMentions();
      }
    },
    async refreshMentions(done = () => {}) {
      if (this.$refs.infiniteScroll) {
        this.$refs.infiniteScroll.$el.scrollTop = 0;
      }

      this.page = 1;
      this.range = this.defaultRange || DateRange.today();

      await this.loadMentions({
        range: this.range,
        clearMentionsCount: true,
        forceReload: true,
        page: this.page,
      });

      this.$emit("refreshed");
      done();
    },
    activateSelector(selector) {
      if (this.selectorActive === selector) {
        this.selectorActive = null;

        this.$track("Closed Mention Stream Filters", {
          streamId: this.stream.id,
        });
      } else {
        this.selectorActive = selector;

        this.$track("Opened Mention Stream Filters", {
          streamId: this.stream.id,
        });
      }
    },
    onFilter(filters) {
      this.filters.collapseSyndicated = filters?.collapseSyndicated ?? true;
      this.searchingRange = false;
      const requestFilters = this.filters.requestFilters();
      if (requestFilters.range) this.range = filters.range;

      if (requestFilters.range || this.filters.filteringSortOptions()) {
        this.searchingRange = true;
        this.reachedOneYear = false;
      } else {
        this.reachedOneYear = false;
      }

      this.setFilters(requestFilters);
      this.page = 1;

      this.loadMentions({
        range: this.range,
        keepAlreadyLoadedMentions: false,
        clearMentionsCount: false,
        page: this.page,
      });
    },
    onFilterReset(filters) {
      this.$track("Clicked Stream Search Reset", {
        streamId: this.stream.id,
      });

      this.filters = filters;

      if (this.onNavBar) {
        this.$navBar.bus.$emit("navbar-button-clicked", "filter");
      }

      this.selectorActive = null;
      this.searchingRange = false;
      this.range = this.defaultRange;
      this.filters.range = this.range;
      this.filters.dateRangeEnabled = false;
      this.filters.collapseSyndicated = true;
      this.filters.includeExternalItems = true;

      this.page = 1;

      this.setFilters(this.filters.requestFilters());

      this.loadMentions({
        keepAlreadyLoadedMentions: false,
        clearMentionsCount: false,
        page: this.page,
      });

      this.setStreamFilters({ streamId: this.stream.id, filters: null });
    },
    onFilterApply(filters) {
      if (this.onNavBar) {
        this.$navBar.bus.$emit("navbar-button-clicked", "filter");
      }

      this.selectorActive = null;

      this.filters = filters;

      if (filters.dateRangeEnabled) {
        this.range = filters.range;
      }

      this.onFilter(filters);

      this.setStreamFilters({
        streamId: this.stream.id,
        filters: this.filters,
      });
    },
    onLoadingErrorRetry() {
      this.$track("Retry Loading Mention Stream After Error", {
        streamId: this.stream.id,
      });

      this.page = 1;

      this.loadMentions({
        range: this.range,
        clearMentionsCount: false,
        page: this.page,
      });
    },
    removeMention(mention) {
      this.$track("Removed from Mention Stream", {
        id: mention.id,
        type: mention.type,
        streamId: this.stream.id,
      });

      this.$nextTick(() =>
        this.mentions.splice(this.mentions.indexOf(mention), 1)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-column-header {
  &.mobile {
    height: 50px;
    padding: 10px;
  }
}

.outlined-icon {
  &.mobile {
    height: 35px;
    width: 35px;
    padding: 7px;
    margin-left: 4px;
  }
}

.banner {
  background-color: var(--s-color-alert);
}

.card-header {
  border-bottom: 0;

  > * {
    border-bottom: 1px solid $hover-background;
  }
}

.card-column-header-button-bar {
  button {
    text-transform: none;
  }
}

.load-error {
  background-color: $error;
  color: white;
}

.expiry {
  background-color: #ddd;
}

.enabled {
  color: $active;

  &.active {
    color: $active;
  }
}

.tooltip-list {
  padding-left: 20px;
}

.shared-icon {
  display: inline-block;
  margin-right: 3px;
  width: 12px;
  height: 12px;
  mask: url("https://assets.streem.com.au/icons/menu/menu-stream-share.svg")
    no-repeat;
  mask-size: cover;
  background-color: currentcolor;
}

.overflow-visible {
  overflow: visible;
}
</style>
