<template>
  <div class="tw-flex tw-flex-col">
    <template v-if="modelValue.length">
      <div
        v-if="filterType === 'author'"
        class="tw-mb-1 tw-flex tw-flex-wrap tw-gap-1"
      >
        <Tag
          v-for="(item, index) in modelValue"
          :key="index"
          :color="MediumField.ONLINE"
          :hide-remove-button="disabled"
          :label="item.name"
          hide-cursor
          rounded
          @remove="removeItem(item)"
        />
      </div>
      <StreamFiltersSelectedAlert
        v-else
        data-cy="selection"
        @click="openAdvancedFilters('selected')"
      >
        <i18n-t
          scope="global"
          keypath="filters_autocomplete.sources_selected"
          :plural="modelValue.length"
        >
          <template #count>
            <span class="tw-font-bold">{{ modelValue.length }}</span>
          </template>
        </i18n-t>
      </StreamFiltersSelectedAlert>
    </template>
    <template v-if="!hideSelector">
      <div>
        <InputText
          v-model="query"
          data-cy="query"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="{ disabled: disabled }"
          autocomplete="off"
          new-design
          :clearable="!loading"
          @keydown.up.stop="decrementSearchIndex"
          @keydown.down.stop="incrementSearchIndex"
          @keydown.enter.stop="onKeyEnterOrTab"
          @keydown.tab.stop="onKeyEnterOrTab"
          @blur="onTextBlur"
          @focus="onTextFocus"
        >
          <template
            v-if="placeholder"
            #prepend
          >
            <BaseIcon
              icon="search"
              class="tw-text-xl"
            />
          </template>
          <template #append>
            <div
              v-if="loading"
              class="tw-px-2"
            >
              <QSpinner />
            </div>
            <div v-else-if="shouldApplyToSources && !isMobile">
              <BaseButton
                v-if="showAdvancedFilters"
                data-cy="advanced-filters"
                new-design
                new-icon
                icon="advanced-filters"
                :disabled="disableAdvancedFilters"
                @click="openAdvancedFilters()"
              />
              <StreemTooltip self="top right">
                <template v-if="disableAdvancedFilters">
                  {{
                    getLocaleText(
                      "filters_autocomplete.advanced_filters_not_supported"
                    )
                  }}
                </template>
                <template v-else>
                  {{
                    getLocaleText(
                      "filters_autocomplete.advanced_filters_tooltip"
                    )
                  }}
                </template>
              </StreemTooltip>
            </div>
          </template>
        </InputText>
      </div>
      <div class="tw-relative">
        <div
          v-if="shouldShowDropdown"
          class="tw-absolute tw-left-0 tw-top-0 tw-z-50 tw-w-full tw-rounded-b tw-border tw-border-t-0 tw-border-solid tw-border-denim-300 tw-bg-white"
          data-cy="dropdown"
          @mousedown.prevent
        >
          <div
            v-if="!hasItems"
            class="tw-p-2"
          >
            <i18n-t
              v-if="!items.length && query.length >= minimumCharacters"
              scope="global"
              tag="div"
              keypath="filters_autocomplete.no_results_found_for"
              class="no-results"
            >
              <template #search>
                <em>{{ query }}</em>
              </template>
            </i18n-t>
            <div
              v-else-if="query.length > 0 && query.length < minimumCharacters"
            >
              {{
                $t("filters_autocomplete.type_at_least", { minimumCharacters })
              }}
            </div>
          </div>
          <div
            v-else
            class="list tw-max-h-[300px] tw-overflow-auto"
          >
            <QInfiniteScroll
              ref="infiniteScroll"
              :offset="40"
              scroll-target=".list"
              @load="loadMoreItems"
            >
              <div
                v-for="(item, index) in indexedItems"
                ref="itemElements"
                :key="index"
                :class="{ 'tw-bg-denim-100': item.focus }"
                class="tw-cursor-pointer tw-select-none tw-p-2 hover:tw-bg-denim-100"
                data-cy="item"
                @mousedown="preventDropdownClose"
                @mouseup="allowDropdownClose"
                @click="selectItem(item)"
              >
                <div class="tw-flex tw-items-center">
                  <span class="tw-flex-1">{{ item.name }}</span>
                  <small v-if="shouldApplyToSources">{{
                    sourceLabel(item)
                  }}</small>
                </div>
                <div
                  v-if="!isSourceGroupMediumLocation(item)"
                  class="tw-text-sm tw-text-denim-700"
                >
                  {{ item.location }}
                </div>
              </div>

              <template #loading>
                <div class="tw-pa-2 tw-flex tw-justify-center">
                  <QSpinnerDots size="2em" />
                </div>
              </template>
            </QInfiniteScroll>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { debounce } from "lodash-es";
import { computed, inject, nextTick, ref, toRefs, watch } from "vue";

import { track } from "shared/boot/analytics";
import { $streemApiAdmin, $streemApiV1 } from "shared/boot/api";
import { getLocaleText } from "shared/boot/i18n";
import { BaseButton, BaseIcon, InputText, Tag } from "shared/components/base";
import StreemTooltip from "shared/components/base/StreemTooltip.vue";
import StreamFiltersSelectedAlert from "shared/components/streams/StreamFilters/StreamFiltersSelectedAlert.vue";
import useSourceFilters from "shared/composables/useSourceFilters";
import {
  getSourceByClass,
  MediumField,
  type MediumName,
} from "shared/helpers/media";
import type { Source } from "shared/helpers/sourceFilters";
import { isSourceGroupMediumLocation } from "shared/helpers/sourceFilters";
import { capitalize } from "shared/helpers/string";
import ModalService from "shared/modals/ModalService";
import { Author } from "shared/resources";
import type ApiClient from "shared/services/api/ApiClient";
import type { Stream } from "shared/types";

export interface FiltersAutocompleteProps {
  minimumCharacters?: number;
  stream: Stream;
  disabled?: boolean;
  filterType: "author" | "sources" | "all_sources";
  media?: MediumName[];
  placeholder?: string;
  keepExpanded?: boolean;
  editingStream?: boolean;
  hideAdvancedFilters?: boolean;
  hideSelector?: boolean;
  useAdvancedFiltersModal?: boolean;
}

interface SourceItem extends Source {
  focus?: boolean;
  color?: string;
}

const props = withDefaults(defineProps<FiltersAutocompleteProps>(), {
  minimumCharacters: 3,
  media: () => [],
  placeholder: "",
});

const modelValue = defineModel<Source[]>({
  default: () => [],
});

const emit = defineEmits(["source-filters-open"]);

const isAdminMode = inject<boolean>("isAdminMode");
const isMobile = inject<boolean>("isMobile");

const { stream, media } = toRefs(props);

const { validMedia } = useSourceFilters({ stream, enabledMedia: media });

const loading = ref(false);
const items = ref<SourceItem[]>([]);
const query = ref("");
const dropdownVisible = ref(false);
const allowDropdownToClose = ref(true);
const itemIndex = ref(0);
const page = ref(1);
const infiniteScroll = ref();
const itemElements = ref([]);

const showAdvancedFilters = computed<Boolean>(() => !props.hideAdvancedFilters);

const modelValueKeys = computed<string[]>(() =>
  modelValue.value.map((item) => `${item.type}-${item.id}`)
);

const filteredItems = computed(() =>
  items.value.filter(
    (item) => !modelValueKeys.value.includes(`${item.type}-${item.id}`)
  )
);

const indexedItems = computed(() =>
  filteredItems.value.map((item, index) => ({
    ...item,
    focus: itemIndex.value === index,
  }))
);

const shouldApplyToSources = computed(() =>
  ["all_sources", "sources"].includes(props.filterType)
);

const hasItems = computed(() => indexedItems.value.length);

const shouldShowDropdown = computed(
  () => dropdownVisible.value && query.value.length
);

const disableAdvancedFilters = computed<boolean>(
  () => Boolean(!validMedia.value.length) || props.disabled
);

function showDropdown() {
  dropdownVisible.value = true;
}

async function sourceSearch(
  api: ApiClient,
  endpoint: string,
  extraParams = {}
) {
  const result = await api.get(endpoint, {
    params: {
      q: query.value,
      page: page.value,
      limit: 50,
      ...extraParams,
    },
  });

  const { data, headers } = result;

  if (page.value >= headers["x-total-pages"]) {
    infiniteScroll.value?.stop();
  }

  items.value.push(...data);
}

async function search() {
  if (!query.value) return;

  itemIndex.value = 0;
  loading.value = true;
  showDropdown();

  if (props.filterType === "author") {
    if (import.meta.env.VITE_AUTHOR_SEARCH === "influencers") {
      const influencers = await Author.where({
        must: {
          match: {
            type: "influencer",
          },
          nested: {
            path: "outlets",
            query: {
              bool: {
                must: {
                  terms: {
                    "outlets.monitoringOnlyFlag": false,
                  },
                },
              },
            },
          },
          matchPhrase: {
            nameAnalysed: query.value,
          },
        },
      })
        .order({ popularity: "desc" })
        .all();

      items.value = influencers.data.map<Source>(
        ({ id, name }) =>
          ({
            id: Number(id),
            name,
            type: "author",
          }) as Source
      );
    } else {
      await sourceSearch($streemApiV1, "streams/journalists");
    }
  } else if (isAdminMode && !props.stream.id) {
    await sourceSearch($streemApiAdmin, "sources", {
      media: props.media,
    });
  } else if (!props.stream.id) {
    await sourceSearch($streemApiV1, "sources", {
      media: props.media,
      include_international_plus: true,
    });
  } else {
    await sourceSearch($streemApiV1, `streams/${props.stream.id}/sources`, {
      ...(props.filterType === "all_sources" && { all_sources: true }),
    });
  }

  page.value += 1;
  loading.value = false;
}

const debouncedSearch = debounce(search, 250);

async function loadMoreItems(index: number, done: () => void) {
  if (!hasItems.value || loading.value) {
    infiniteScroll.value?.stop();
    done();

    return;
  }

  await search();

  done();
}

function sourceLabel(item: Source) {
  if (isSourceGroupMediumLocation(item)) {
    return getLocaleText("filters_autocomplete.source_group");
  }

  return getSourceByClass(item.type).label;
}

function hideDropdown() {
  dropdownVisible.value = false;
  itemIndex.value = 0;
  page.value = 1;
  query.value = "";
}

function selectItem(item: Source) {
  modelValue.value = [...modelValue.value, { ...item }];

  if (!props.keepExpanded) {
    hideDropdown();
  }
}

function removeItem(item: Source) {
  const modelValueIndex = modelValue.value.indexOf(item);
  const newValue = [...modelValue.value];
  newValue.splice(modelValueIndex, 1);
  modelValue.value = newValue;
}

function onTextBlur() {
  if (!allowDropdownToClose.value) return;

  hideDropdown();
}

function onTextFocus() {
  showDropdown();
}

type ItemElement = HTMLDivElement & {
  scrollIntoViewIfNeeded?: (centerIfNeeded: boolean) => void;
};

function scrollOptionIntoView() {
  nextTick(() => {
    const el: ItemElement = itemElements.value[itemIndex.value];

    if (!el) return;

    if (el.scrollIntoViewIfNeeded !== undefined) {
      el.scrollIntoViewIfNeeded(false);
    } else if (el.scrollIntoView !== undefined) {
      el.scrollIntoView({ block: "nearest" });
    }
  });
}

function decrementSearchIndex() {
  if (itemIndex.value - 1 >= 0) {
    itemIndex.value -= 1;
    scrollOptionIntoView();
  }
}

function incrementSearchIndex() {
  if (itemIndex.value + 1 < filteredItems.value.length) {
    itemIndex.value += 1;
    scrollOptionIntoView();
  }
}

function onKeyEnterOrTab() {
  let item: Partial<Source> = {};

  if (itemIndex.value >= 0) {
    item = filteredItems.value[itemIndex.value];
  }

  if (itemIndex.value === -1 && props.filterType === "author") {
    item.name = capitalize(query.value);
  }

  if (!item.name) return;
  selectItem(item as Source);
  showDropdown();
}

function preventDropdownClose() {
  allowDropdownToClose.value = false;
}

function allowDropdownClose() {
  allowDropdownToClose.value = true;
}

function openAdvancedFilters(initialView?: string) {
  if (!props.useAdvancedFiltersModal) {
    track("Click to open Advanced Source Filter popup");

    ModalService.open("AdvancedSourceFiltersModal", {
      props: {
        selectedSources: modelValue.value,
        initialView,
        stream: props.stream,
        readOnly: disableAdvancedFilters.value,
        editingStream: props.editingStream,
        enabledMedia: props.media,
      },
      events: {
        selectedSources: (selectedSources: Source[]) => {
          modelValue.value = selectedSources;
        },
      },
    });

    return;
  }

  track("Click to open Advanced Filters popup");

  emit("source-filters-open", initialView);
}

watch(query, () => {
  if (query.value.length >= props.minimumCharacters) {
    items.value = [];
    page.value = 1;
    debouncedSearch();
  } else if (items.value) {
    items.value = [];
    itemIndex.value = 0;
  }
});
</script>
