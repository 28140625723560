<template>
  <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
    <div class="tw-flex tw-items-center tw-gap-1">
      <div class="tw-text-sm">{{ label }}</div>
      <BaseButtonDropdown
        :label="selectedSort && selectedSort.label"
        bordered
        chevron
        color="white"
        data-testid="sort-dropdown"
        new-design
        new-dropdown
        placement="bottom-start"
        size="sm"
      >
        <div class="tw-max-h-[300px] tw-overflow-auto">
          <BaseButtonList
            :model-value="selectedSort"
            :options="sortPossibilityOptions"
            data-testid="sort-options"
            @update:model-value="setSortBy"
          />
        </div>
      </BaseButtonDropdown>
    </div>

    <div class="tw-flex tw-items-center tw-gap-1">
      <div class="tw-text-sm">
        {{ getLocaleText("sort_order_picker.order_by_label") }}
      </div>
      <BaseButtonDropdown
        :label="selectedOrder && selectedOrder.label"
        bordered
        chevron
        close-on-click
        color="white"
        data-testid="order-dropdown"
        new-design
        new-dropdown
        placement="bottom-end"
        size="sm"
      >
        <BaseButtonList
          :model-value="selectedOrderOption"
          :options="orderOptions"
          data-testid="order-options"
          @update:model-value="setOrderBy"
        />
      </BaseButtonDropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getLocaleText, getLocaleValue } from "shared/boot/i18n";
import { BaseButtonDropdown, BaseButtonList } from "shared/components/base";
import {
  getOrderOptions,
  getSortPossibilities,
  type OrderOption,
  type SortOption,
  type SortOptionField,
  SortOptionMissing,
} from "shared/helpers/mentions";
import { type StreamFiltersSortOption } from "shared/helpers/StreamFilters";

const modelValue = defineModel<StreamFiltersSortOption>({ required: true });

interface SortOrderPickerProps {
  excludeSortOptionKeys?: SortOptionField[];
  label?: string;
}

const props = withDefaults(defineProps<SortOrderPickerProps>(), {
  excludeSortOptionKeys: () => [],
  label: () => getLocaleValue("sort_order_picker.default_sort_by_label"),
});

const emit = defineEmits<{ change: [StreamFiltersSortOption] }>();

const orderOptions = computed<OrderOption[]>(() => getOrderOptions());

const selectedOrderOption = computed<OrderOption | undefined>(() =>
  orderOptions.value.find((option) => option.field === modelValue.value.orderBy)
);

const sortPossibilityOptions = computed<SortOption[]>(() => {
  const possibilities = getSortPossibilities();

  if (!props.excludeSortOptionKeys.length) {
    return possibilities;
  }

  return possibilities.filter(
    ({ field }) => !props.excludeSortOptionKeys.includes(field)
  );
});

const selectedSort = computed<SortOption | undefined>(() =>
  sortPossibilityOptions.value.find(
    ({ field }) => field === modelValue.value.sortBy
  )
);

const selectedOrder = computed<OrderOption | undefined>(() =>
  orderOptions.value.find(({ field }) => field === modelValue.value.orderBy)
);

function setSortBy(sortOption: SortOption): void {
  if (modelValue.value.sortBy !== sortOption.field) {
    modelValue.value = {
      ...modelValue.value,
      sortBy: sortOption.field,
    };

    emit("change", modelValue.value);
  }
}

function setOrderBy(order: OrderOption): void {
  if (modelValue.value.orderBy !== order.field) {
    modelValue.value = {
      ...modelValue.value,
      orderBy: order.field,
      missing: SortOptionMissing.LAST,
    };

    emit("change", modelValue.value);
  }
}
</script>
