<template>
  <MentionCard
    ref="card"
    v-intersection="intersectionConfig"
    :title="mentionTitle"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :mentions="mentions"
    :stream="stream"
    :action-sheet-component="actionSheetComponent"
    :options="options"
    :no-highlighting="noHighlighting"
    :show-checkbox="showCheckbox"
    :always-show-keywords="alwaysShowKeywords"
    :selected="selected"
    card-title-class="hover:tw-underline"
    :class="mentionCardClass"
    @click="mentionClicked($event)"
    @mention-removed="removeMention"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #before>
      <div
        v-if="mention.on_front_page && mention.og_image"
        :style="{ backgroundImage: 'url(\'' + mention.og_image + '\')' }"
        class="article-thumbnail"
      />
    </template>

    <template #header>
      <SyndicationsPopper
        v-if="mention?.syndications?.length"
        v-slot="{ toggleOpen }"
        :mention="mention"
        :stream="stream"
        :parent-el-ref="card?.$el"
        @syndication-clicked="mentionClicked($event)"
      >
        <BaseButton
          new-design
          plain
          class="hover:tw-font-bold"
          @click.stop.prevent="toggleOpen"
        >
          <LineClamp
            class="tw-break-all tw-text-sm tw-text-denim-900"
            :lines="1"
          >
            {{ mention.source.name }}

            <template #after="{ clamped }">
              <StreemTooltip v-if="clamped">{{
                mention.source.name
              }}</StreemTooltip>
            </template>
          </LineClamp>

          <span class="tw-text-sm tw-text-denim-900">
            +{{ mention.syndications.length }}
          </span>
        </BaseButton>
      </SyndicationsPopper>
      <LineClamp
        v-else
        tag="span"
        class="alternate source-title tw-break-all hover:tw-font-bold"
      >
        <span class="tw-text-sm tw-text-denim-900">{{
          mention.source.name
        }}</span>

        <template #after="{ clamped }">
          <StreemTooltip v-if="clamped">{{
            mention.source.name
          }}</StreemTooltip>
        </template>
      </LineClamp>
      <span class="softer tw-text-sm tw-leading-4">
        <TimeAgo
          :date="mention.timestamp"
          :timezone="mention.source.time_zone"
        />
        <AuthorWidget
          v-if="mention.author"
          :disable-click="options.disableAuthorClick"
          :mention="mention"
        />
        <span>
          {{
            $t("online_mention_card.word_count", {
              wordCount: mention.word_count,
            })
          }}
        </span>
      </span>
    </template>

    <slot />

    <template #list-body-append>
      <div @click.stop>
        <SocialAmplifyWidget
          v-if="card"
          is-list-view
          :mention="mention"
          :is-parent-visible="isVisible"
          :root-element="card.$el"
          scroll-into-view
          dense
          @click="onClick"
        />
      </div>
    </template>

    <template #afterBody>
      <MentionCardInsights
        v-if="!hideMentionCardMetrics"
        :visible-widgets="visibleWidgets"
        :widget-keys="[sentimentWidgetKey, socialAmplifyWidgetKey]"
        @click.stop.prevent
      >
        <template #components>
          <PotentialAudienceReachWidget
            :mention="mention"
            dense
          />

          <AdvertisingValueWidget
            :mention="mention"
            dense
          />

          <SentimentWidget
            :key="sentimentWidgetKey"
            :mention="mention"
            :stream="stream"
            dense
          />

          <ReactScoreWidget
            :mention="mention"
            :is-parent-visible="isVisible"
            dense
          />

          <ImpactScoreWidget
            :impact-score="mention.impact_score"
            dense
          />

          <SocialAmplifyWidget
            v-if="card"
            :key="socialAmplifyWidgetKey"
            :mention="mention"
            :is-parent-visible="isVisible"
            :root-element="card.$el"
            scroll-into-view
            dense
            @update-social-amplify-widget-key="updateSocialAmplifyWidgetKey"
            @click="onClick"
          />

          <DomainAuthorityWidget
            :mention="mention"
            dense
          />
        </template>
      </MentionCardInsights>

      <MentionTranslationLanguage
        v-if="showTranslationLanguage"
        :mention="mention"
      />
    </template>

    <template #after>
      <!-- Related Print Article -->
      <div v-if="canShowRelatedArticle">
        <RelatedMentionCard
          :action-sheet-component="actionSheetComponent"
          :mention="mention.related_article"
          :stream="stream"
          @mention:click="mentionClicked($event)"
          @syndication:click="mentionClicked($event)"
        >
          {{ $t("online_mention_card.view_print_version") }}
        </RelatedMentionCard>
      </div>
    </template>
  </MentionCard>
</template>

<script>
import { storeToRefs } from "pinia";
import { ref } from "vue";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import AuthorWidget from "shared/components/AuthorWidget.vue";
import { BaseButton, LineClamp, StreemTooltip } from "shared/components/base";
import TimeAgo from "shared/components/core/various/TimeAgo.vue";
import DomainAuthorityWidget from "shared/components/DomainAuthorityWidget.vue";
import ImpactScoreWidget from "shared/components/ImpactScoreWidget.vue";
import { MentionCard, props } from "shared/components/MentionCard";
import MentionCardInsights from "shared/components/MentionCardInsights.vue";
import MentionTranslationLanguage from "shared/components/MentionTranslationLanguage.vue";
import PotentialAudienceReachWidget from "shared/components/PotentialAudienceReachWidget.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import { RelatedMentionCard } from "shared/components/RelatedMentionCard";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import SocialAmplifyWidget from "shared/components/SocialAmplifyWidget.vue";
import { SyndicationsPopper } from "shared/components/SyndicationsPopper";
import useMention from "shared/composables/useMention";
import useSocialAmplify from "shared/composables/useSocialAmplify";
import { useUniversalSearchStore } from "shared/stores/universalSearch";
import { useUserStore } from "shared/stores/user";

export default {
  name: "OnlineMentionCard",
  components: {
    AdvertisingValueWidget,
    AuthorWidget,
    BaseButton,
    DomainAuthorityWidget,
    LineClamp,
    MentionCard,
    MentionCardInsights,
    MentionTranslationLanguage,
    SocialAmplifyWidget,
    PotentialAudienceReachWidget,
    ReactScoreWidget,
    ImpactScoreWidget,
    RelatedMentionCard,
    SentimentWidget,
    StreemTooltip,
    SyndicationsPopper,
    TimeAgo,
  },
  props: {
    ...props,
    hideMentionCardMetrics: {
      type: Boolean,
    },
  },
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const card = ref();

    const { isMentionNotClickable, mentionClicked } = useMention(
      mentionProps,
      context
    );

    const universalSearchStore = useUniversalSearchStore();
    const { searchOpen } = storeToRefs(universalSearchStore);

    const userStore = useUserStore();

    const { adminUserEnabled, betaEnabled, currentUser, isAdminUser } =
      storeToRefs(userStore);

    const { hasAmplify, totalSocialAmplifyVolume } = useSocialAmplify({
      mention: mentionProps.mention,
    });

    return {
      card,
      isMentionNotClickable,
      mentionClicked,
      searchOpen,
      adminUserEnabled,
      betaEnabled,
      currentUser,
      isAdminUser,

      hasAmplify,
      totalSocialAmplifyVolume,
    };
  },
  data() {
    return {
      isVisible: false,
      hasRecorded: false,
      intersectionConfig: {
        handler: this.onIntersection,
        cfg: {
          threshold: [0, 0.25, 0.5, 0.75, 1],
        },
      },
      sentimentValue: null,
      socialAmplifyValue: 0,
    };
  },
  computed: {
    sentimentWidgetKey() {
      return `sentiment-widget-${this.mention.id}-${this.sentimentValue}`;
    },
    socialAmplifyWidgetKey() {
      return `social-amplify-widget-${this.mention.id}-${this.socialAmplifyValue}`;
    },
    canShowReactScore() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    visibleWidgets() {
      const widgets = ["SentimentWidget"];

      if (this.card && this.hasAmplify) {
        widgets.push("SocialAmplifyWidget");
      }

      if (this.mention.source?.domain_authority)
        widgets.push("DomainAuthorityWidget");

      if (this.mention.audience) widgets.push("PotentialAudienceReachWidget");

      if (
        this.mention.advertising_value &&
        this.$features.has("advertising_value_mentions")
      ) {
        widgets.push("AdvertisingValueWidget");
      }

      if (this.canShowReactScore) widgets.push("ReactScoreWidget");

      if (this.mention.impact_score?.grade) {
        widgets.push("ImpactScoreWidget");
      }

      return widgets;
    },
    canShowRelatedArticle() {
      return (
        this.$features.has("view_paper_articles") &&
        this.mention.related_article &&
        (!this.searchOpen || this.adminUserEnabled)
      );
    },
    mentionTitle() {
      return this.mention.translated_title || this.mention.title;
    },
    showTranslationLanguage() {
      return this.mention.original_language !== "English";
    },
    mentionCardClass() {
      if (this.isMentionNotClickable(this.mention)) return null;

      return "pointer";
    },
  },
  watch: {
    "mention.sentiment_ratings": {
      deep: true,
      handler(options) {
        this.sentimentValue = options[0]?.sentiment;
      },
    },
  },
  mounted() {
    this.mention.sentiment_ratings ??= [];
  },
  methods: {
    onIntersection({ isIntersecting, intersectionRatio }) {
      this.isVisible = isIntersecting;

      this.recordView();

      if (intersectionRatio < 0.75) {
        this.isVisible = false;
      }
    },
    updateSocialAmplifyWidgetKey(amplifyVolume) {
      this.socialAmplifyValue = amplifyVolume;
    },
    onClick() {
      this.$refs.card.$el.scrollIntoView({ behaviour: "smooth" });
    },
    recordView() {
      if (this.isVisible && this.$route.name !== "curated-email-report") {
        setTimeout(() => {
          if (this.isVisible && !this.isAdminUser && !this.hasRecorded) {
            this.hasRecorded = true;

            this.$track("Saw Online Article", {
              userId: this.currentUser.id,
              articleId: this.mention.id,
              sourceId: this.mention.source.id,
            });
          }
        }, 300);
      }
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
  },
};
</script>

<style lang="scss" scoped>
.article-thumbnail {
  width: 100%;
  background-size: cover;
  background-position: 50% 30%;
  height: 120px;
}

.helper-text {
  margin-top: 9px;
}

.source-title :deep(.ellipsis) {
  display: inline-block;
  max-width: 210px;
  white-space: normal;
  vertical-align: bottom;
}

.after-body {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>
