<template>
  <div class="tw-ml-auto tw-flex tw-flex-nowrap tw-items-center tw-gap-0.5">
    <slot name="prependButtonBar" />

    <template v-if="!hideRangeSelector">
      <div v-if="isDesktop">
        <div class="tw-flex tw-items-center">
          <InputDatePicker
            v-model:range="range"
            :allow-all-time="allowAllTime"
            range-selection="custom"
            close-on-update
            show-date-input
            show-time-input
            show-range-options
            show-update-button
            limit-to-past-year
            bordered
            class="e2e-date-range"
            data-testid="date-range-picker"
          />
          <StreemTooltip>
            {{ getLocaleText("mention_stream.select_date_range") }}
          </StreemTooltip>
        </div>
      </div>

      <div v-else>
        <BaseButton
          new-design
          color="secondary"
          icon="ion-md-time"
          :bordered="rangeSelectorActive"
          :aria-label="getLocaleText('mention_stream.select_date_range')"
          :label="rangeLabel"
          flat
          data-testid="date-range-button"
          @click="rangeSelectorActive = !rangeSelectorActive"
        />
      </div>
    </template>

    <BaseButtonGroup gap="xs">
      <BaseButton
        v-if="showInsertExternalItemIcon"
        v-bind="barButtonAttrs"
        icon="insert-external-item"
        data-testid="external-item"
        @click="openExternalItemEdit"
      >
        <StreemTooltip
          anchor="top middle"
          self="bottom right"
        >
          {{ getLocaleText("mention_stream.add_external_item_button") }}
        </StreemTooltip>
      </BaseButton>

      <BaseButton
        v-if="canGenerateStreamReports"
        v-bind="barButtonAttrs"
        icon="create-report"
        data-testid="create-report"
        @click="openMentionStreamReport"
      >
        <StreemTooltip
          anchor="top middle"
          self="bottom right"
        >
          {{ getLocaleText("mention_stream.generate_report_button") }}
        </StreemTooltip>
      </BaseButton>

      <BaseButton
        v-if="!hideFiltersSelector && stream.id"
        v-bind="barButtonAttrs"
        :active-color="filtering ? 'activated' : 'secondary'"
        :active="filtersSelectorActive"
        :color="filtering ? 'activated' : 'white'"
        icon="filter"
        class="e2e-filter"
        data-testid="filter"
        @click="emit('filters')"
      >
        <StreemTooltip
          anchor="top middle"
          self="bottom right"
        >
          {{ getLocaleText("mention_stream.filter_mentions_button") }}
        </StreemTooltip>
      </BaseButton>

      <BaseButton
        v-if="!hideEditButton"
        v-bind="barButtonAttrs"
        icon="settings"
        class="e2e-edit"
        data-testid="edit"
        @click="openEditStream"
      >
        <StreemTooltip
          anchor="top middle"
          self="bottom right"
        >
          {{ getLocaleText("mention_stream.edit_mention_stream_button") }}
        </StreemTooltip>
      </BaseButton>
    </BaseButtonGroup>

    <slot name="buttonBar" />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { useRouter } from "vue-router";

import { track } from "shared/boot/analytics";
import { getLocaleText } from "shared/boot/i18n";
import {
  BaseButton,
  BaseButtonGroup,
  InputDatePicker,
  StreemTooltip,
} from "shared/components/base";
import { type ButtonSize } from "shared/components/base/BaseButton.vue";
import { streamTypes } from "shared/constants";
import type DateRange from "shared/helpers/DateRange";
import { safePush } from "shared/helpers/routing";
import features from "shared/services/features";
import Permissions from "shared/services/Permissions";
import { type Stream } from "shared/types";

interface MentionStreamHeaderToolbarButtonAttrs {
  newDesign: boolean;
  newIcon: boolean;
  color: string;
  size: ButtonSize;
  bordered: boolean;
  iconSize: string;
}

interface MentionStreamHeaderToolbarProps {
  allowAllTime?: boolean;
  filtering?: boolean;
  filtersSelectorActive?: boolean;
  groupSlug?: string;
  hideEditButton?: boolean;
  hideFiltersSelector?: boolean;
  hideGenerateReportButton?: boolean;
  hideRangeSelector?: boolean;
  stream: Stream;
}

const props = withDefaults(defineProps<MentionStreamHeaderToolbarProps>(), {
  groupSlug: "",
});

const emit = defineEmits<{
  filters: [];
}>();

const range = defineModel<DateRange>("range");

const router = useRouter();
const safeRouterPush = safePush({ router });
const isDesktop = inject<boolean>("isDesktop", false);
const permissions = new Permissions();

const rangeSelectorActive = ref<boolean>(false);

const showInsertExternalItemIcon = computed<boolean>(() => {
  if (props.stream.type === streamTypes.bookmarkStream) return false;

  return (
    isDesktop &&
    features.has("has_external_items") &&
    permissions.has("can_insert_external_items")
  );
});

const canGenerateStreamReports = computed<boolean>(
  () =>
    !props.hideGenerateReportButton &&
    features.hasAll(["has_reports", "streams_reports"]) &&
    permissions.has("can_generate_stream_reports")
);

const rangeLabel = computed<string>(() =>
  range.value ? range.value.toString().replace("Last ", "") : ""
);

const barButtonAttrs = computed<MentionStreamHeaderToolbarButtonAttrs>(() => ({
  newDesign: true,
  newIcon: true,
  color: "white",
  size: isDesktop ? "sm" : "md",
  bordered: true,
  iconSize: "lg",
}));

const isBookmarkStream = computed<boolean>(
  () => props.stream.type === streamTypes.bookmarkStream
);

const externalItemRouteName = computed<string>(() =>
  isBookmarkStream.value ? "bookmark-external-item-new" : "external-item-new"
);

const reportRouteName = computed<string>(() =>
  isBookmarkStream.value ? "bookmark-report" : "mention-stream-report-new"
);

function openEditStream() {
  track("Opened Edit Mention Stream", {
    streamId: props.stream.id,
  });

  safeRouterPush({
    name: "mention-stream-edit",
    params: {
      streamSlug: props.stream.slug,
      groupSlug: props.groupSlug,
    },
  });
}

function openExternalItemEdit() {
  track("Clicked on Add External Item icon above Mention Stream");

  safeRouterPush({
    name: externalItemRouteName.value,
    params: {
      streamId: props.stream.id,
      streamSlug: props.stream.slug,
      groupSlug: props.groupSlug,
    },
  });
}

function openMentionStreamReport() {
  track("Opened Mention Stream Report", {
    streamId: props.stream.id,
  });

  safeRouterPush({
    name: reportRouteName.value,
    params: {
      selectedStreamId: props.stream.id,
      streamSlug: props.stream.slug,
      groupSlug: props.groupSlug,
    },
    props,
  });
}
</script>
