<template>
  <div
    :class="{ active: active }"
    class="tw-shrink-0"
  >
    <slot name="beforeTags" />

    <template v-if="hideInput">
      <span :class="errorClass">{{ hideInputLabel }}</span>
    </template>

    <template v-else>
      <div
        ref="inputWrapper"
        class="tw-relative"
      >
        <InputText
          v-model="query"
          new-design
          :debounce="300"
          :placeholder="getLocaleText('global.search')"
          data-testid="query"
          @click="visible = true"
        />
        <Popper
          v-if="filteredBookmarkStreams.length && visible"
          v-model="visible"
          placement="bottom-start"
          same-width
          :root-element="inputWrapper"
        >
          <div
            ref="bookmarkList"
            class="c-dropdown-menu tw-max-h-[260px] tw-overflow-auto tw-bg-white"
          >
            <div class="tw-flex tw-flex-col tw-gap-2 tw-px-4 tw-py-2">
              <div v-if="!query">
                <Checkbox
                  v-model="selectAll"
                  :label="$t('expandable_filters.all_tags')"
                  new-design
                  dense
                  :indeterminate="isIndeterminate"
                  class="tw-font-bold"
                  @update:model-value="toggleSelectAll"
                />
              </div>
              <div
                v-for="(bookmarkStream, index) in filteredBookmarkStreams"
                :key="index"
              >
                <Checkbox
                  v-model="bookmarkStream.selected"
                  :label="bookmarkStream.label"
                  new-design
                  dense
                  :data-testid="`tag-option-${bookmarkStream.id}`"
                  @update:model-value="
                    updateSelectedBookmarkStreams(bookmarkStream)
                  "
                >
                  <template #label>
                    <Tag
                      :color="labelColor(bookmarkStream)"
                      :label="bookmarkStream.label"
                      :palette="palette"
                      hide-remove-button
                      rounded
                    />
                  </template>
                </Checkbox>
              </div>
            </div>
          </div>
        </Popper>
      </div>

      <div
        v-if="tags.length"
        class="tw-mt-2 tw-flex tw-flex-wrap tw-gap-1"
        data-testid="tags"
      >
        <Tag
          v-for="bookmarkStream in tags"
          :key="bookmarkStream.label"
          :data-testid="`tag-${bookmarkStream.id}`"
          :color="labelColor(bookmarkStream)"
          :label="bookmarkStream.label"
          :palette="palette"
          hide-cursor
          rounded
          @remove="removeBookmarksStream(bookmarkStream)"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core/index";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { InputText, Tag } from "shared/components/base";
import Popper from "shared/components/base/Popper.vue";
import Checkbox from "shared/components/core/forms/Checkbox.vue";
import {
  defaultBookmarkPalette,
  streamTypes,
  tagPalette,
} from "shared/constants";
import type { StreamFiltersTag } from "shared/helpers/StreamFilters";
import { useStreamsStore } from "shared/stores/streams";
import type { Stream } from "shared/types";

export interface DropdownTagsProps {
  active?: boolean;
  stream: Stream;
  hideInput?: boolean;
  hideInputLabel?: string;
  errorClass?: string;
}

const tags = defineModel<StreamFiltersTag[]>("tags", {
  default: () => [],
});

const props = withDefaults(defineProps<DropdownTagsProps>(), {
  hideInputLabel: "",
  errorClass: "",
});

const inputWrapper = ref<HTMLDivElement>();
const bookmarkList = ref<HTMLDivElement | null>(null);
const query = ref<string>("");
const selectAll = ref<boolean>(false);
const visible = ref<boolean>(false);

const streamsStore = useStreamsStore();

const { mediaBookmarkStreams, socialBookmarkStreams } =
  storeToRefs(streamsStore);

const palette = computed(() => ({
  ...tagPalette,
  ...defaultBookmarkPalette,
}));

const emit = defineEmits<{
  search: [];
}>();

onMounted(() => {
  onClickOutside(bookmarkList, () => {
    visible.value = false;
  });
});

const bookmarkStreams = computed<Stream[]>(() => {
  if (props.stream.type === streamTypes.socialStream) {
    return socialBookmarkStreams.value;
  }

  return mediaBookmarkStreams.value;
});

const filteredBookmarkStreams = computed<StreamFiltersTag[]>(() =>
  bookmarkStreams.value.reduce((acc: StreamFiltersTag[], stream) => {
    if (stream.label.toLowerCase().includes(query.value.toLowerCase())) {
      acc.push({
        id: stream.id,
        label: stream.label,
        color: stream.color,
        selected: tags.value.some((tag) => tag.id === stream.id),
      });
    }

    return acc;
  }, [])
);

const labelColor = (bookmark: StreamFiltersTag) =>
  bookmark.color || Object.keys(defaultBookmarkPalette)[0];

const addBookmarkStream = (bookmarkStream: StreamFiltersTag) => {
  tags.value = [...tags.value, bookmarkStream];

  emit("search");
};

const isIndeterminate = computed(
  () => tags.value.length > 0 && !selectAll.value
);

const removeBookmarksStream = (bookmarkStream: StreamFiltersTag) => {
  tags.value = tags.value.filter(
    (selectedBookmarkStream) => bookmarkStream.id !== selectedBookmarkStream.id
  );

  emit("search");
};

const updateSelectedBookmarkStreams = (bookmarkStream: StreamFiltersTag) => {
  const existingTag = tags.value.find(
    (selectedBookmarkStream) => selectedBookmarkStream.id === bookmarkStream.id
  );

  if (existingTag) {
    removeBookmarksStream(bookmarkStream);
  } else {
    addBookmarkStream(bookmarkStream);
  }
};

const toggleSelectAll = () => {
  let selectedBookmarksStreams: StreamFiltersTag[] = [];

  if (selectAll.value) {
    selectedBookmarksStreams = bookmarkStreams.value.map(
      (bookmark: StreamFiltersTag) => ({
        id: bookmark.id,
        label: bookmark.label,
        color: bookmark.color,
      })
    );
  }

  tags.value = selectedBookmarksStreams;
  emit("search");
};

function resetFilter() {
  if (visible.value) return;

  query.value = "";
}

function updateSelectAll() {
  selectAll.value = tags.value.length === bookmarkStreams.value.length;
}

watch(visible, () => resetFilter());

watch(
  () => tags.value,
  () => updateSelectAll()
);
</script>
