<template>
  <div class="tw-flex tw-flex-col tw-gap-2">
    <div
      v-for="(sortOption, index) in modelValue"
      :key="index"
      class="tw-flex tw-justify-between"
    >
      <SortOrderPicker
        :model-value="modelValue[index]"
        :exclude-sort-option-keys="excludedSortingOptions"
        :label="
          single ? $t('sorting_expandable.sort_by') : ordinalNumber(index + 1)
        "
        @update:model-value="updateSortOrder($event, index)"
      />
      <BaseButton
        v-if="modelValue.length > 1"
        new-design
        new-icon
        icon="bin"
        size="sm"
        @click="removeSortingOption(index)"
      />
    </div>
    <div
      v-if="!single"
      class="tw-flex"
    >
      <BaseButton
        new-design
        new-icon
        icon="plus"
        size="sm"
        :title="$t('sorting_expandable.add_sorting')"
        @click="addSortingOptions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from "shared/components/base/BaseButton.vue";
import SortOrderPicker from "shared/components/core/pickers/SortOrderPicker.vue";
import {
  SortOptionField,
  SortOptionMissing,
  SortOptionOrder,
} from "shared/helpers/mentions";
import { ordinalNumber } from "shared/helpers/number";
import { type StreamFiltersSortOption } from "shared/helpers/StreamFilters";

interface SortingExpandableProps {
  excludedSortingOptions: SortOptionField[];
  single?: boolean;
}

const modelValue = defineModel<StreamFiltersSortOption[]>({
  default: () => [],
});

defineProps<SortingExpandableProps>();

function addSortingOptions(): void {
  modelValue.value = [
    ...modelValue.value,
    {
      sortBy: SortOptionField.TIMESTAMP,
      orderBy: SortOptionOrder.DESC,
      missing: SortOptionMissing.LAST,
    },
  ];
}

function removeSortingOption(index: number): void {
  const options = modelValue.value;
  options.splice(index, 1);
  modelValue.value = [...options];
}

function updateSortOrder(value: StreamFiltersSortOption, index: number): void {
  modelValue.value = modelValue.value.toSpliced(index, 1, value);
}
</script>
