import type { AxiosResponse } from "axios";

import { $streemApiV1 } from "shared/boot/api";
import { defaultMentionsFields } from "shared/constants";
import DateRange from "shared/helpers/DateRange";
import type { MediumName } from "shared/helpers/media";
import { getMediaForStream, getTypesForStream } from "shared/helpers/media";
import type { SortOptionField, SortOptionOrder } from "shared/helpers/mentions";
import serializeParamsForRequest from "shared/helpers/request";
import type {
  StreamRequestFilters,
  StreamRequestFiltersSortOption,
} from "shared/helpers/StreamFilters";
import useSyndicationStore from "shared/stores/syndication";
import type { Stream } from "shared/types";
import type { Mention, MentionType } from "shared/types/mentions";

export interface FetchMentionsOptions {
  allFields?: boolean;
  bundle_broadcast?: boolean;
  case_sensitive?: boolean;
  collapse_syndicated?: boolean;
  endpoint?: string;
  excerpt_radius?: number;
  excludedMedia?: MediumName[];
  fields?: string;
  filter_most_read_sources?: boolean;
  includeBookmarkSyndication?: boolean;
  keepAlreadyLoadedMentions?: boolean;
  keyword?: string;
  keywords?: string;
  limit?: number;
  loadLatestMentions?: boolean;
  media?: MediumName[];
  page?: number;
  range: DateRange;
  search?: StreamRequestFilters;
  skipMediaValidation?: boolean;
  sort_options?: StreamRequestFiltersSortOption[];
  sortBy?: SortOptionField;
  sortOrder?: SortOptionOrder;
  source_group_keys?: string[];
  source_keys?: string[];
  stream_ids?: number[];
  syndicationKeys?: string[];
  timeout?: number;
  types?: MentionType[];
  useMilliseconds?: boolean;
}

interface FetchMentionsParams extends StreamRequestFilters {
  after: number;
  before: number;
  bundle_broadcast?: boolean;
  case_sensitive?: boolean;
  category_ids?: number[];
  collapse_syndicated?: boolean;
  excerpt_radius: number;
  excluded_syndication_keys?: string[];
  fields?: string;
  filter_most_read_sources?: boolean;
  include_bookmark_syndication?: boolean;
  keyword?: string;
  limit: number;
  media?: MediumName[];
  page?: number;
  query?: string;
  sort_by?: SortOptionField;
  sort_options?: StreamRequestFiltersSortOption[];
  sort_order?: SortOptionOrder;
  source_group_keys?: string[];
  source_keys?: string[];
  stream_ids?: number[];
  timestamps_in_milliseconds?: boolean;
  types?: string[];
}
interface FetchMentionsBuildOptions {
  params: FetchMentionsParams;
  timeout?: number;
}

export interface FetchMentionsEmptyResponse {
  data: Mention[];
  headers: { "x-total-count": number; "x-total-pages": number };
}

interface FetchMentionsAggregateOptions {
  collapseSyndicated?: boolean;
  field?: string;
  media?: MediumName[];
  search?: StreamRequestFilters;
  types?: MentionType[];
}

export const fetchMentions = {
  buildOptions({
    stream,
    mentions,
    media,
    options = { range: DateRange.lastYear() },
  }: {
    stream: Stream;
    mentions: Mention[];
    media: MediumName[];
    options: FetchMentionsOptions;
  }): FetchMentionsBuildOptions {
    let { after, before } = options.range;

    let timestampProp: keyof Mention = "timestamp";

    if (options.useMilliseconds) {
      timestampProp = "timestamp_milliseconds";
      after *= 1000;
      before *= 1000;
    }

    const latestMention: Mention | undefined = options.loadLatestMentions
      ? mentions.at(0)
      : mentions.at(-1);

    if (options.keepAlreadyLoadedMentions && latestMention && !options.page) {
      before = latestMention[timestampProp];
    }

    let types = getTypesForStream(stream);

    if (options.types) {
      types = options.types;
    }

    const query =
      options.endpoint === "search/journalists"
        ? stream.keywords
        : this.parseQuery(stream.keywords || "");

    return {
      ...(options.timeout ? { timeout: options.timeout } : {}),
      params: {
        after,
        before,
        excerpt_radius: options.excerpt_radius || 60,
        limit: options.limit ? options.limit : 10,
        ...(options.allFields || !stream.id
          ? {}
          : { fields: options.fields || defaultMentionsFields }),
        ...(options.collapse_syndicated ? { collapse_syndicated: true } : {}),
        ...(options.bundle_broadcast ? { bundle_broadcast: true } : {}),
        ...(options.sort_options ? { sort_options: options.sort_options } : {}),
        ...(options.sortBy ? { sort_by: options.sortBy } : {}),
        ...(options.sortOrder ? { sort_order: options.sortOrder } : {}),
        ...(options.page ? { page: options.page } : {}),
        ...(media?.length ? { media } : {}),
        ...(options.case_sensitive !== undefined
          ? { case_sensitive: options.case_sensitive }
          : {}),
        ...(options.syndicationKeys
          ? { excluded_syndication_keys: options.syndicationKeys }
          : {}),
        ...(options.useMilliseconds
          ? { timestamps_in_milliseconds: options.useMilliseconds }
          : {}),
        ...(types?.length ? { types } : {}),
        ...(options.includeBookmarkSyndication !== undefined
          ? { include_bookmark_syndication: options.includeBookmarkSyndication }
          : {}),
        ...(options.stream_ids && { stream_ids: options.stream_ids }),
        ...(stream.filters && {
          category_ids: stream.filters.map(({ filter }) => filter.id),
        }),
        ...(options.source_keys && { source_keys: options.source_keys }),
        ...(options.source_group_keys && {
          source_group_keys: options.source_group_keys,
        }),
        ...(options.keyword && { keyword: options.keyword }),
        ...(options.search ? options.search : {}),
        ...(query ? { query } : {}),
      },
    };
  },

  get({
    stream,
    mentions,
    options = { range: DateRange.lastYear() },
  }: {
    stream: Stream;
    mentions: Mention[];
    options: FetchMentionsOptions;
  }): Promise<AxiosResponse<Mention[]> | FetchMentionsEmptyResponse> {
    const media = fetchMentions.streamMedia(stream, options);

    const { skipMediaValidation } = options;

    if (!media.length && !skipMediaValidation) {
      return Promise.resolve({
        data: [],
        headers: { "x-total-count": 0, "x-total-pages": 0 },
      });
    }

    let endpoint = "search";

    if (options.endpoint) {
      endpoint = options.endpoint;
    } else if (stream.id) {
      endpoint = `streams/${stream.id}/mentions`;
    }

    const params = fetchMentions.buildOptions({
      stream,
      mentions,
      options: {
        ...options,
        ...(!stream.id ? { excerpt_radius: 150 } : {}),
      },
      media,
    });

    return $streemApiV1.post(endpoint, params);
  },

  streamMedia(stream: Stream, options: FetchMentionsOptions) {
    let media = getMediaForStream(stream);

    const { excludedMedia } = options;

    if (excludedMedia?.length) {
      media = media.filter((medium) => !excludedMedia.includes(medium));
    }

    return media;
  },

  aggregate(
    stream: Stream,
    options: FetchMentionsAggregateOptions,
    range: DateRange
  ) {
    const params = {
      after: range.after,
      before: range.before,
      ...(options.collapseSyndicated ? { collapse_syndicated: true } : {}),
      ...(options.media ? { media: options.media } : {}),
      ...(options.field ? { field: options.field } : {}),
      types: getTypesForStream(stream),
      ...(options.types ? { types: options.types } : {}),
      ...(options.search ? options.search : {}),
    };

    const serializedParams = serializeParamsForRequest(params);

    return $streemApiV1
      .get(`streams/${stream.id}/aggregate?${serializedParams}`)
      .then((response) => response.data);
  },

  mentionCount({
    stream,
    after,
    before,
    search,
  }: {
    stream: Stream;
    after: number;
    before: number;
    search: StreamRequestFilters;
  }) {
    const { streamSyndicationKeys } = useSyndicationStore() as unknown as {
      streamSyndicationKeys: {
        [key: number]: string[];
      };
    };

    const syndicationKeys = streamSyndicationKeys[stream.id] || [];

    return $streemApiV1.post(`streams/${stream.id}/mention_count`, {
      params: {
        after,
        before,
        limit: 0,
        syndicationKeys,
        ...search,
      },
      handleErrors: false,
    });
  },

  getIds({
    stream,
    mentions,
    options,
  }: {
    stream: Stream;
    mentions: Mention[];
    options: FetchMentionsOptions;
  }): Promise<AxiosResponse | FetchMentionsEmptyResponse> {
    const media = fetchMentions.streamMedia(stream, options);

    const { skipMediaValidation } = options;

    if (!media.length && !skipMediaValidation) {
      return Promise.resolve({
        data: [],
        headers: { "x-total-count": 0, "x-total-pages": 0 },
      });
    }

    const requestOptions = fetchMentions.buildOptions({
      stream,
      media,
      mentions,
      options,
    });

    return $streemApiV1.post(
      `streams/${stream.id}/mention_ids`,
      requestOptions
    );
  },

  parseQuery(query: string): string {
    const regexp = "(AND NOT|AND|OR|NOT|UNLESS|NEAR/|ONEAR/|FIRST/|ATLEAST/)";

    if (query.match(new RegExp(regexp))) {
      return query;
    }

    const keywords: string[] = [];

    query
      .replace(/"/g, "")
      .split(/,(?!(\d))/)
      .forEach((word) => {
        if (word) keywords.push(`"${word.trim()}"`);
      });

    return keywords.join(",");
  },
};

export default fetchMentions;
