export * from "./utility";

import type { MediumName } from "shared/helpers/media";
import { type StreamOptionsFilters } from "shared/helpers/StreamFilters";
import type { Stream as StreamResource } from "shared/resources";

import type { ConvertKeysToSnakeCase, Nullable } from "./utility";

export interface Origin {
  origin_id: number;
  origin_type: string;
  origin_keyword?: string;
}

export interface Target {
  target_id: number;
  target_type: string;
}

export interface BaseSentimentRating extends Origin, Target {
  id: number;
  origin_id: number;
  origin_type: string;
  target_id: number;
  target_type: string;
  created_at: string;
  updated_at: string;
  user: {
    id: number;
    name: string;
  };
  sentiment: number;
}

export interface SentimentRating extends Partial<BaseSentimentRating> {
  sentiment: number;
}

export interface MediaAndTranscriptRequestQuery {
  id: number;
  label: string;
  isMediaItems?: boolean;
  isTranscriptRequest?: boolean;
  position: number;
}

export interface StreamGroup {
  id: number;
  stream_type: number;
  social_bookmarks_stream: boolean;
  slug: string;
  label: string;
  color: string;
  organisation_id: number;
  organisation_team_id: Nullable<number>;
  user_id: Nullable<number>;
  position: number;
  shared: boolean;
  can_modify: boolean;
  user?: {
    id: number;
    name: string;
  };
  user_accessibles?: {
    accessible_id: number;
    accessible_type: string;
    user_id: number;
    access_level: string;
  }[];
  streams: {
    id: number;
    label: string;
    color: string;
    archived_at: string;
    shared: boolean;
  }[];
  locked: boolean;
  shared_groups: {
    user_id: number;
    can_edit: boolean;
    access_level: string;
  }[];
}

interface StreamFilter {
  filter: {
    id: number;
    visible?: boolean;
  };
}

export interface Stream
  extends Partial<
    Omit<
      ConvertKeysToSnakeCase<StreamResource>,
      | "id"
      | "user"
      | "group"
      | "filters"
      | "sources"
      | "stream_notifications"
      | "streams_users"
      | "typed_attributes"
    >
  > {
  auto_refresh: boolean;
  can_modify: boolean;
  color: string;
  count?: number;
  dashboard_enabled: boolean;
  dashboard_only: boolean;
  default_sentiment_rating: number;
  enabled_media: MediumName[];
  filters?: StreamFilter[];
  group: {
    id: number;
    position: number;
    slug: string;
    label: string;
    color: string;
    organisation_team_id: Nullable<number>;
  };
  group_id: number;
  has_streams_user: boolean;
  hydration_status: Nullable<number>;
  id: number;
  label: string;
  locked: boolean;
  onlyLastSeenUpdated?: boolean;
  options?: Nullable<StreamOptionsFilters>;
  position: Nullable<number>;
  primary: boolean;
  refreshed_at: Nullable<number | string>;
  refreshAt?: number;
  resetByGroup?: boolean;
  report_enabled: boolean;
  sentiment_mode_enabled: boolean;
  shared_with_me: boolean;
  shared: boolean;
  slug: string;
  skipReload?: boolean;
  social_bookmarks_stream: boolean;
  social_impact_threshold: number;
  social_only_verified: boolean;
  team_ids: Nullable<number[]>;
  type: number;
  user?: {
    id: number;
  };
  user_id: number;
}

export type Uuid = `${string}-${string}-${string}-${string}-${string}`;

export type SortingOrder = "asc" | "desc";

export enum DataLoadingWrapperState {
  Done = "done",
  Loading = "loading",
  Error = "error",
  NoData = "noData",
}
