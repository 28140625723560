<template>
  <div
    class="tw-pointer-cursor tw-pa-2 tw-flex tw-min-h-[42px] tw-items-center tw-justify-center tw-text-balance tw-bg-red-400 tw-text-center tw-text-sm tw-font-bold tw-leading-none tw-text-white"
    @click="emit('click')"
  >
    <slot>
      {{ label }}
    </slot>
  </div>
</template>

<script setup lang="ts">
const { label = "" } = defineProps<{ label: string }>();

const emit = defineEmits(["click"]);
</script>
